@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;
.mdc-notched-outline__notch {
  border-right: none !important;
}

@include mat.core();

$watchtower-ui-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$watchtower-ui-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$watchtower-ui-warn: mat.m2-define-palette(mat.$m2-red-palette);

$watchtower-ui-theme: mat.m2-define-light-theme((
  color: (
    primary: $watchtower-ui-primary,
    accent: $watchtower-ui-accent,
    warn: $watchtower-ui-warn,
  ),
));

@include mat.all-component-themes($watchtower-ui-theme);

a {
  @apply cursor-pointer;
  color: mat.m2-get-color-from-palette($watchtower-ui-primary);

  mat-icon {
    @apply align-top;
  }

  &[target="_blank"]:after {
    font-family: "Material Icons";
    content: "\e89e";
    @apply align-bottom pl-1;
  }

  &.clipboard:after {
    font-family: "Material Icons";
    content: "\e14d";
    @apply align-bottom pl-1;
  }

  &.editable:after {
    font-family: "Material Icons";
    content: "\e3c9";
    @apply align-bottom pl-1;
  }
}

@import 'assets/styles/global';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
