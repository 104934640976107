@use '@angular/material' as mat;

mat-form-field {
  @include mat.all-component-densities(-5);
  @apply mb-8;
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    @apply p-0;
  }
}

form mat-form-field {
  @apply w-full;
}

.dl-grid {
  @apply grid grid-cols-2 gap-4;
}

dl {
  dt {
    @apply text-gray-500;
  }

  dd:not(:last-child) {
    @apply mb-4;
  }
}

app-root {
  @apply flex flex-col h-full;
}

.mat-drawer-container {
  flex: 1 1 auto;
}

div.grid {
  position: relative;

  dl:not(:first-child):before {
    content: '';
    position: absolute;
    margin-left: -1rem;
    height: 100%;
    border-left: 1px solid lightgray;
  }
}

.form-page {
  @apply max-w-4xl;
  form {
    @apply pt-4 lg:w-3/4;
  }

  mat-card {
    @apply mb-4;
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  @apply pt-1 #{!important};
}
